import * as React from "react"
import Header from '@Components/Header/Header';
import Footer from '@Components/Footer/Footer';
import BookViewing from "@Components/BookViewing/MakeanOffer"
import  './BookViewing.scss';
import { Helmet } from "react-helmet";
import SEO from "@Components/seo";
const BookViewingPage = ( props ) => {
  const [state,setState] = React.useState({
    showMenu:false
  })

  const handlerClick = () => {
    // console.log('okkk')
    setState({...state,showMenu:!state.showMenu})
  }

  
  return(
    <div className={state.showMenu ? "wrapper bookview-page header-style open-search-block" : "wrapper bookview-page header-style"}>
              <SEO title={'Property Valuation Manchester'} description={'What’s your property worth? Find out using a more accurate valuation service, courtesy of Bentley Hurst. Our no-obligation valuation is waiting.'} />
      <Helmet
        bodyAttributes={{
            class: `templates-default-template alias`
        }}
      />
    <Header showMenu={state.showMenu} handlerClick={handlerClick}/>
    <BookViewing/> 
    <Footer/>
    </div>
         
  
)
  }



export default BookViewingPage