import React, { useState, useEffect } from "react";
import { Form, Container, Row, Col } from 'react-bootstrap';
import './BookViewing.scss'
import noImage from "../../images/no-image.png";
import { get } from 'lodash';
import ContactForm from "@Components/forms/make-an-offer"
import { isAuthenticated, isBrowser, doRedirect, storeData } from "@myaccount/website-service";
const BookViewing = (props) => {
  const [data, updateData] = useState({})
  const property_image = get(data, "images[0]['730x492']", get(data, 'images[0].url', noImage))
  const [formvalues, setFormvalues] = useState("");
  const [token, setToken] = useState("");
  const handleBack = () => window.history.back();
  useEffect(() => {
    window.scrollTo(0, 0);
    updateData(JSON.parse(sessionStorage.getItem('data')))
  }, []);
  console.log("data => ", data);

  const authUser = isAuthenticated();

  // My accoount
  if (isBrowser() && !authUser) {
    storeData('auth_return', window.location.href);
    doRedirect(process.env.GATSBY_MYACCOUNT_URL);
  }

  return (
    <div className="header-style wrapper">
    <div style={{ position: "relative" }}>
      <Container>
        <Row>
          <Col lg="6">
          <div className="make-an-offer-step stepone">
            <div className="d-lg-block">
              <div onClick={handleBack} onKeyPress={handleBack} className="back_icon"><i className="icon-back"></i> Back to Property Details</div>
            </div>  
            <h2>So you want to Make an Offer. Let’s get started.</h2>
              {!authUser && (
                <h2>Loading...</h2>
              )}
              {authUser && (
                <ContactForm data={data} />
              )}
            </div>
          </Col>
          {property_image &&
              <div className="bg-image-book-view" style={{ backgroundImage: `url(${property_image})` }}></div>
          }

        </Row>

      </Container>
    </div>
    </div>


  )
}



export default BookViewing
